.modal {
    &#new-message {
        .modal-content {
            padding: $padding-largest;
        }
    }

    &#user-details {
        .modal-content {
            padding: $padding-large;
        }

        .user-details-container {
            padding-bottom: $padding-large;
        }
    }

    &#client-payment {
        .modal-content {
            padding: $padding-large;
        }
    }

    &#new-message, &#user-details, &#client-payment{
        .modal-header {
            padding-bottom: $padding-large;
            border-color: $border-container;
        }
    }


}

@include media-breakpoint-down(lg) {
    .modal {
        &#new-message {
            .modal-content {
                padding: $padding-30 $padding-smallest;
            }
        }
    
        // &#user-details {
        //     .modal-content {
        //         padding: $padding-large;
        //     }
    
        //     .user-details-container {
        //         padding-bottom: $padding-large;
        //     }
        // }
    
        // &#new-message, &#user-details{
        //     .modal-header {
        //         padding-bottom: $padding-large;
        //         border-color: $border-container;
        //     }
        // }
    
    
    }
}

@include media-breakpoint-down(s) {
    .modal {
        &#new-message {
            .modal-content {
                .modal-buttons {
                    flex-direction: column;

                    button {
                        width: 100%;
                        margin: 0 !important;

                        &#new-message-cancel {
                            margin-bottom: $padding-smallest !important;
                        }
                    }
                }
            }
        }
    }
}